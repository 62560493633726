import React from 'react';
import { PageProps } from 'gatsby';

const Connect: React.FC<PageProps> = () => {
  React.useEffect(() => {
    window.location.href = 'https://www.ieee.org/';
  }, []);
  return <div />;
};

export default Connect;
